.excerpt {
	min-height: 9rem;
}

.category {
	position: absolute;
	left: 50%;
	top: 0;
	transform: translateY(-50%) translateX(-50%);
}

.image {
	padding-bottom: 60%;
}

.thumbnailMoreButtonContainer {
	justify-self: end;
}
