@media (max-width: 768px) {
	.menu {
		position: absolute;
		left: -100%;
		transition: 0.1s;
		background-color: #fff;
	}

	.open {
		height: 100vh;
		padding: 2rem;
		top: 0;
		left: 0;
		right: 0;
		z-index: 10;
	}

	.menuToggleOpen {
		position: fixed;
		top: 2rem;
		right: 2rem;
	}

	.subItem::last-child {
		padding-bottom: 0;
	}
}

.sub {
	position: relative;
	padding-right: 1rem;
}

.container:hover .sub:after {
	border-top-color: #f2af22;
}

.sub:after {
	top: 45%;
	left: calc(100% - 1rem);
	border: solid transparent;
	content: " ";
	cursor: pointer;
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 0, 0, 0);
	border-top-color: #000000;
	border-width: 5px;
	margin-left: -5px;
	z-index: 10;
}

@media (min-width: 768px) {
	.subItem:not(:last-child) {
		border-bottom: 1px solid #CBD5E0;
	}
}


