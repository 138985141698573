button.peach, a.peach {
	background-color: #eb9f4a;
}

button.peach:hover, a.peach:hover {
	background-color: #f2af22;
}

button.red, a.red {
	background-color: #c8202b;
	color: #fff;
}

button.red:hover, a.red:hover {
	background-color: #4b240a;
	color: #fff;
}

button.brown, a.brown {
	background-color: #4b240a;
	color: #fff;
}

button.brown:hover, a.brown:hover {
	background-color: #000000;
}

button.black, a.black {
	background-color: #000000;
	color: #fff;
}

button.black:hover, a.black:hover {
	background-color: #4b240a;
}

button.red-outline, a.red-outline {
	background: #fff;
	border: 1px solid #c8202b;
	color: #c8202b;
}

button.red-outline:hover, a.red-outline:hover {
	background: #fff;
	border: 1px solid #4b240a;
	color: #4b240a;
}
