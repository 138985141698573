.container {
	@apply mx-auto;
	width: calc(980px + 4rem);
	@apply px-4;
}

@media (max-width: 980px) {
	.container {
		margin: 0;
		padding: 0 2rem;
		width: 100%;
	}
}
