.container {
  position: fixed!important;
  z-index: 999;
}

.loader {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.loader:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #c8202b;
  border-color: #c8202b transparent #c8202b transparent;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
