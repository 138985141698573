.banner {
	max-height: 115px;
}

@media (max-width: 640px) {
	.banner {
		max-height: 125px;
	}
}


.tile {
	padding-bottom: 90%;
}

.rect {
	padding-bottom: calc(180% + 1rem);
}

